import React from "react";
import PropTypes from "prop-types";

import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

function Membership({ data, pageContext }) {
  const { parent, children, siblings } = pageContext;
  const { frontmatter, html } = data.markdownRemark;

  return (
    <Layout
      fixedHeader={frontmatter.image ? true : false}
      parent={parent}
      title={frontmatter.title}
    >
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title={frontmatter.title}
      />

      <PageTemplate
        title={frontmatter.title}
        image={frontmatter.image}
        intro={frontmatter.intro}
        html={html}
        parent={parent}
        childPages={children}
        siblings={siblings}
      />
    </Layout>
  );
}

export const PageTemplate = ({ html, intro, title, image }) => {
  return (
    <>
      <section
        className="relative flex items-center justify-center w-full h-screen py-16 bg-black"
        style={{ maxHeight: "600px" }}
      >
        <img
          alt="Festivals"
          className="absolute inset-0 object-cover w-full h-full opacity-75"
          src={image}
        />
        <div className="container relative z-10 px-2 text-center text-white">
          <h2 className="font-extrabold text-white title pattern-text ">
            {title}
          </h2>
          <h4 className="text-4xl font-semibold text-white normal-case">
            {intro}
          </h4>
        </div>
      </section>
      {html && (
        <section className="">
          <article className=" prose prose-xl">
            <iframe
              src="https://membermojo.co.uk/baladoairfield?embed"
              style={{
                width: "100%",
                height: "1200px",
                border: "none",
                minHeight: "100vh",
              }}
            ></iframe>
          </article>
        </section>
      )}
    </>
  );
};

PageTemplate.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  intro: PropTypes.string,
  html: PropTypes.string,
  parent: PropTypes.object,
  childPages: PropTypes.array,
  siblings: PropTypes.array,
};

Membership.propTypes = {
  pageContext: PropTypes.object,
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
      html: PropTypes.string,
    }),
  }),
};

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        image
        intro
        widgets {
          title
          text
          link
          image
        }
      }
      html
    }
  }
`;

export default Membership;
